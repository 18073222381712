import { useRouter } from 'next/router'
import type { AppProps } from 'next/app'
import { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import '../styles/Landing.css'
import '../styles/globals.css'
import { Toaster } from 'sonner'
import { trpc } from '../utils/trpc'
import { PublicEnv } from '@/public-env'
import { QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { DefaultSeo } from 'next-seo'
import { OPINLY_COLOR } from '@opinly/core/src/colors'
import {
  ClerkProvider as ClerkProviderNext,
  useAuth,
  useOrganization,
  useUser,
} from '@clerk/nextjs'
import { TRPCClientError, httpBatchLink } from '@trpc/react-query'
import { PersistQueryClientProvider, Persister } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google'
import Script from 'next/script'
import { Dancing_Script } from 'next/font/google'

// const dancingScript = Dancing_Script({
//   subsets: ['latin'],
//   variable: '--dancing-script',
// })

let persister: Persister

if (typeof window !== 'undefined') {
  persister = createSyncStoragePersister({
    storage: window?.localStorage,
  })
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const { user, isLoaded } = useUser()
  const profile = trpc.profile.profile.useQuery(undefined, {
    enabled: Boolean(user),
  })

  const auth = useAuth()

  const intercom = trpc.profile.intercom.useQuery(undefined, {
    enabled: Boolean(user),
  })

  const { organization, isLoaded: organizationLoaded } = useOrganization()

  useEffect(() => {
    if (!isLoaded || !organizationLoaded) {
      return
    }

    if (router.pathname.startsWith('/admin')) {
      if (!user) {
        router.push('/')
        return
      }

      if (user.publicMetadata.userType !== 'ADMIN') {
        router.push('/')
        return
      }
    }

    if (router.pathname.startsWith('/dashboard')) {
      if (!user) {
        router.push('/pricing')
      } else {
        if (organization?.id) {
          return
        }

        if (!user.publicMetadata.isSubscribed) {
          if (organization?.id) {
            return
          }

          router.push('/pricing')
          return
        }

        if (!user.publicMetadata.onboarded) {
          router.push('/onboarding')
          return
        }

        if (!user.publicMetadata.verified) {
          router.push('/dashboard/onboarding-complete')
          return
        }
      }
    }

    if (router.pathname === '/onboarding') {
      if (!user || !user.publicMetadata.isSubscribed) {
        router.push('/auth/pricing')
        return
      }

      if (user.publicMetadata.onboarded) {
        if (!user.publicMetadata.verified) {
          router.push('/onboarding-complete')
          return
        }

        router.push('/dashboard')
        return
      }
    }
  }, [user, router.pathname])

  useEffect(() => {
    if (!user || !profile.data || !intercom.data || !auth.isLoaded) return

    if (auth.actor) {
      return
    }

    const createdAtInSeconds = user.createdAt
      ? Math.floor(user.createdAt.getTime() / 1000)
      : undefined

    sendGTMEvent({
      event: 'setUserData',
      user_id: user.id,
      email_address: user.primaryEmailAddress?.emailAddress,
      phone_number: user.primaryPhoneNumber?.phoneNumber,
      full_name: user.fullName,
      first_name: user.firstName,
      last_name: user.lastName,
      is_subscribed: user.publicMetadata.isSubscribed,
      subscription_created_at: profile.data.subscriptionCreatedAt,
      plan_type: user.publicMetadata.planType,
      user_created_at: createdAtInSeconds,
      profile_url: user.imageUrl,
      intercom_hash: intercom.data,
    })
  }, [user, profile.data, intercom.data])

  return (
    <main
      // className={`${dancingScript.variable} scroll-smooth antialiased [font-feature-settings:'ss01']`}
      className={`scroll-smooth antialiased [font-feature-settings:'ss01']`}
    >
      <DefaultSeo
        defaultTitle="Opinly"
        title="Opinly"
        description="Opinly is an AI powered competitor monitoring and analysis platform for companies."
        themeColor={OPINLY_COLOR}
        twitter={{
          site: 'https://www.opinly.ai',
          cardType: 'summary_large_image',
          handle: '@opinlyai',
        }}
        openGraph={{
          type: 'website',
          url: 'https://www.opinly.ai',
          siteName: 'Opinly',
          description:
            'Opinly is an AI powered competitor monitoring and analysis platform for companies.',
          title: 'Opinly',
          images: [
            {
              url: 'https://www.opinly.ai/files/open-graph-1.png',
              alt: 'Opinly',
              type: 'image/png',
            },
          ],
        }}
      />
      <ReactQueryDevtools initialIsOpen={false} buttonPosition={'bottom-right'} />
      <Toaster richColors expand={true} />
      <Component {...pageProps} />
      <Script
        src="https://js.hs-scripts.com/46467431.js"
        id="hs-script-loader"
        strategy="afterInteractive"
      />
      {/* <Script src={`https://www.googletagmanager.com/gtag/js?id=G-03WJWKHVGM`} />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', 'G-03WJWKHVGM');
        `}
      </Script> */}
    </main>
  )
}

function TRPCWrapper(props: AppProps) {
  const auth = useAuth()

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: (failureCount: number, error: unknown) => {
              if (error instanceof TRPCClientError) {
                if (PublicEnv.NEXT_PUBLIC_STAGE !== 'production') {
                  return false
                }

                const retryableCodes = new Set([
                  'BAD_REQUEST',
                  'TIMEOUT',
                  'INTERNAL_SERVER_ERROR',
                  'TOO_MANY_REQUESTS',
                  'FORBIDDEN',
                ])

                if (!retryableCodes.has(error.data.code)) {
                  return false
                }
              }

              return failureCount < 3
            },
          },
        },
      })
  )

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          /**
           * If you want to use SSR, you need to use the server's full URL
           * @link https://trpc.io/docs/ssr
           **/

          url: `${PublicEnv.NEXT_PUBLIC_API_URL}/trpc`,
          // fetch: async (input, init?) => {
          //   const fetch = getFetch()
          //   return fetch(input, {
          //     ...init,
          //     credentials: 'include',
          //   })
          // },
          // You can pass any HTTP headers you wish here
          async headers() {
            const token = await auth.getToken({
              template: PublicEnv.NEXT_PUBLIC_JWT_TEMPLATE,
            })

            return {
              Authorization: 'Bearer ' + token,
            }
          },
        }),
      ],
    })
  )

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{
          persister,
        }}
      >
        <MyApp {...props} />
      </PersistQueryClientProvider>
    </trpc.Provider>
  )
}

export default function AppWrapper(props: AppProps) {
  return (
    <>
      <ClerkProviderNext
        {...props.pageProps}
        appearance={{
          variables: {
            colorPrimary: 'rgb(24 24 27)',
            colorText: 'rgb(24 24 27)',
          },
        }}
      >
        <TRPCWrapper {...props} />
      </ClerkProviderNext>
      <GoogleTagManager gtmId="GTM-5K5PC9WF" />
      <Script id="default-c">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}

          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
    
          gtag('consent', 'default', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted'
          });
          `}
      </Script>
    </>
  )
}
